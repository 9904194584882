export default {

    userId: "547494009770082305",
    github: "AnemoSTR",
    description: "Hello, I am Anemos, I have been coding for about 2 years. I started coding with Node.js, then I improved myself in areas such as web python, I still use python in general, I still have knowledge about libraries such as next.js and electron, but I cannot start some languages ​​because I am still a student at the moment. I'm trying to improve myself as much as I can.",
    about: {
        description: "Hello. My Name İs <span class='text-primary'>Anemos</span>. I am <span class='text-primary'>16 years</span> old. I am a <span class='text-primary'>2rd year student</span> and I interested in <span class='text-primary'>Software</span>. I've been coding <span class='text-primary'>since 2019</span> and I want to improve myself in this field.",
        liveIn: "İstanbul, Şişli",
        birthday: "16 June, 2007",
        gender: "Man"
    }

}
