export default {

    active: [

        {
            name: "Yakında!!",
            description: "Find the best discord bot for your server in Yakında!! and upgrade your discord experience. WEBSITE WILL BE OPENED #SOON.",
            image: "https://cdn.discordapp.com/attachments/986274488452808735/1066121954064617482/image.png",
            role: "Partner",
            link: "https://www.deeprisebot.tk"
        },
        {
            name: "Deeprise Türkiye",
            description: "Deeprise Türkiye sunucusu insanlar için yenilikler getirebilecek bir kapasitede olup sunucu içinde; Eğlence odaları, Discord Bot Beta Komutları, Yenilikler-Haberler gibi odalar barındırıyordur. Eğer sizde bize destek sağlamak isterseniz; Sunucumuza katılmanız yeterlidir",
            image: "https://cdn.discordapp.com/attachments/1173328579182993479/1254548481327235144/Afis.png?ex=6679e4d2&is=66789352&hm=1dc5d8da7053d3490b523435725da2ce389347e60b8bac7d14fffef8c3b5caaf&",
            role: "Partner",
            link: "https://discord.gg/4cSUcdJG9S"
        }

    ]

}
